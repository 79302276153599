<template>
  <div class="divisions-summary">
    !! NOT IMPLEMENTED !!

  </div>
</template>

<script>

export default {
  components: {
    // AccountsAging: () => import('./accounts-aging'),
    // AccountsCollectionSegments: () => import('./accounts-collection-segments'),
    // AccountsSpark: () => import('./accounts-spark'),
    // AccountsStrategy: () => import('./accounts-strategy'),
    // AccountsWorkQueues: () => import('./accounts-work_queues')
  },
  activated () {
    this.$store.commit('setModule', { name: this.$t('t.Divisions') })
  }
}
</script>
<style lang="stylus" scoped>
.divisions-summary
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  padding 0.5em
  align-content flex-start

.divisions-summary>*
  margin 0.5em
</style>
